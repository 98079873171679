import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "о-дизайн-токенах"
    }}>{`О дизайн-токенах`}</h1>
    <p>{`Дизайн-токены - это простые пары ключ-значение (`}<inlineCode parentName="p">{`black = #000`}</inlineCode>{`, `}<inlineCode parentName="p">{`имя = значение`}</inlineCode>{`),
которые задаются в дизайн-макете и дальше переносятся в код.`}</p>
    <p>{`Дизайн-токены позволяют лучше контролировать консистентность дизайна через переиспользование
токенов и способствуют передаче информации от дизайнера к разработчику. В данном
случае информацией является не только значение токена, но и его назначение, определяемое
с помощью имени. Передав разработчику вместо простых значений ("текст цвета `}<inlineCode parentName="p">{`#ff0000`}</inlineCode>{`,
размера `}<inlineCode parentName="p">{`13px`}</inlineCode>{`") соответствующие имена ("текст цвета error размера small"), дизайнер
также передает ему дополнительную информацию, которую иначе пришлось бы фиксировать
где-то еще.`}</p>
    <p>{`Дизайн-токены сужают пространство выбора, как для дизайнера, так и для разработчиков, что
позволяет обдумать все варианты и выбрать наиболее подходящий.
Например, задавая цвет с помощью hex-кода,
вы можете выбрать любой из `}<inlineCode parentName="p">{`256*256*256=16777216`}</inlineCode>{` цветов. А задав два десятка цветов
с помощью дизайн-токенов, вы сужаете пространство выбора до 20.`}</p>
    <p>{`В большинстве случаев мы рекомендуем использовать значения из токенов.
Однако в сложных случаях вы можете использовать в рамках проекта дополнительные значения,
если это оправдано. `}<a parentName="p" {...{
        "href": "/contacts"
      }}>{`Напишите нам`}</a>{`, если вам не хватает какого-либо токена.`}</p>
    <h2 {...{
      "id": "какие-токены-есть-в-проекте"
    }}>{`Какие токены есть в проекте`}</h2>
    <ul>
      <li parentName="ul">{`Цвета (color / opacity)`}</li>
      <li parentName="ul">{`Параметры текста (font-size / line-height / font-family / font-weight)`}</li>
      <li parentName="ul">{`Стандартные размеры элементов (sizing)`}</li>
      <li parentName="ul">{`Стандартные отступы (spacing)`}</li>
      <li parentName="ul">{`Закругления (radius)`}</li>
      <li parentName="ul">{`Брейкпоинты (breakpoint)`}</li>
      <li parentName="ul">{`Тени (shadow)`}</li>
      <li parentName="ul">{`Анимации (transition)`}</li>
    </ul>
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <h3 {...{
      "id": "scss"
    }}>{`SCSS`}</h3>
    <p>{`Для использования токенов в SCSS импортируйте соответствующие файлы:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import '~@hse-design/tokens/lib/scss/breakpoints.scss';
@import '~@hse-design/tokens/lib/scss/fontFamily.scss';
@import '~@hse-design/tokens/lib/scss/fontSize.scss';
@import '~@hse-design/tokens/lib/scss/fontWeight.scss';
@import '~@hse-design/tokens/lib/scss/lineHeight.scss';
@import '~@hse-design/tokens/lib/scss/opacity.scss';
@import '~@hse-design/tokens/lib/scss/radius.scss';
@import '~@hse-design/tokens/lib/scss/shadow.scss';
@import '~@hse-design/tokens/lib/scss/sizing.scss';
@import '~@hse-design/tokens/lib/scss/spacing.scss';
@import '~@hse-design/tokens/lib/scss/transitions.scss';
@import '~@hse-design/tokens/lib/scss/colorsAccent.scss';
@import '~@hse-design/tokens/lib/scss/colorsBackground.scss';
@import '~@hse-design/tokens/lib/scss/colorsBase.scss';
@import '~@hse-design/tokens/lib/scss/colorsBrand.scss';
@import '~@hse-design/tokens/lib/scss/colorsElementOverlay.scss';
@import '~@hse-design/tokens/lib/scss/colorsGray.scss';
@import '~@hse-design/tokens/lib/scss/colorsOverlay.scss';
@import '~@hse-design/tokens/lib/scss/colorsScreenOverlay.scss';
@import '~@hse-design/tokens/lib/scss/colorsSystem.scss';
`}</code></pre>
    <p>{`Если вы используете стили дизайн-системы в своем проекте, то прямой импорт токенов
не требуется. Достаточно импортировать файл базовых переменных из пакета
`}<inlineCode parentName="p">{`@hse-design/core`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import '~@hse-design/core/src/base.scss';
`}</code></pre>
    <p>{`Далее в вашем SCSS-файле любой из дизайн-токенов можно использовать в виде
SCSS-переменной по имени, указанному в данной документации:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.someClass {
  margin-top: $spacing-small;
  width: $sizing-40;
  height: $sizing-40;
  background-color: $color-brand;
}
`}</code></pre>
    <p>{`При верстке макетов вашего продукта используйте дизайн-токены в виде переменных,
а не их значения. Так сохраняется их семантика.`}</p>
    <h3 {...{
      "id": "javascript"
    }}>{`JavaScript`}</h3>
    <p>{`Значения токенов при необходимости также можно использовать в javascript-коде,
при этом переменные токенов именуются в camelCase.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { colorBrand } from '@hse-design/tokens';
`}</code></pre>
    <h3 {...{
      "id": "scss-переменные"
    }}>{`SCSS-переменные`}</h3>
    <p>{`В `}<inlineCode parentName="p">{`@hse-design/core`}</inlineCode>{` есть дополнительные SCSS-переменные и миксины, которые могут быть
полезны при верстке продуктов.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gitlab.com/proscom/hse-design/-/blob/master/packages/core/src/z-index.scss"
        }}>{`Шкала z-index`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gitlab.com/proscom/hse-design/-/blob/master/packages/core/src/mixins/_focusShadow.scss"
        }}>{`миксин focus-shadow`}</a>{` - для создания фокусной рамки`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gitlab.com/proscom/hse-design/-/blob/master/packages/core/src/mixins/_focusVisible.scss"
        }}>{`миксин focus-visible`}</a>{` - для использования :focus-visible с полифиллом без postcss`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gitlab.com/proscom/hse-design/-/blob/master/packages/core/src/mixins/_resetDefaultInput.scss"
        }}>{`миксин hide-default-input`}</a>{` - для скрытия input в кастомизированных чекбоксах`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gitlab.com/proscom/hse-design/-/blob/master/packages/core/src/mixins/_resetDefaultInput.scss"
        }}>{`миксин reset-default-input`}</a>{` - для отмены стандартных стилей input`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      